<template>
  <ul class="list">
    <li v-if="theme_store.themeData.showSearch" @click="handleSearch">
      <el-tooltip content="搜索">
        <el-icon><Search /></el-icon>
      </el-tooltip>
    </li>
    <li v-if="theme_store.themeData.showLock" @click="handleLock">
      <el-tooltip content="锁屏">
        <el-icon><Lock /></el-icon>
      </el-tooltip>
    </li>
    <li v-if="theme_store.themeData.showRefresh" @click="handleReFresh">
      <el-tooltip content="刷新">
        <el-icon><Refresh /></el-icon>
      </el-tooltip>
    </li>
    <li v-if="theme_store.themeData.showLanguage">
      <el-tooltip content="国际化">
        <el-dropdown trigger="click" @command="handleCommand">
          <el-icon :size="16"><Basketball /></el-icon>
          <template #dropdown>
            <el-dropdown-menu>
              <el-dropdown-item
                v-for="(item, i) in useI18nStore.localeList"
                :command="item.value"
                >{{ item.label }}</el-dropdown-item
              >
            </el-dropdown-menu>
          </template>
        </el-dropdown>
      </el-tooltip>
    </li>
    <li v-if="theme_store.themeData.showTheme" @click="handleTheme">
      <el-tooltip content="主题">
        <el-icon><Brush /></el-icon>
      </el-tooltip>
    </li>
    <li v-if="theme_store.themeData.showFullScreen" @click="handleFullScreen">
      <el-tooltip content="全屏">
        <el-icon :size="14"><FullScreen /></el-icon>
      </el-tooltip>
    </li>
    <el-tooltip content="通知">
    <li v-if="theme_store.themeData.showNotice" @click="handleNotice">
        <el-popover :width="320" trigger="click">
          <template #reference>
            <el-badge :value="2" :offset="[0, 0]">
              <el-icon><Bell /></el-icon>
            </el-badge>
          </template>
          <el-tabs v-model="state.activeName">
            <el-tab-pane label="通知" name="notification"></el-tab-pane>
            <el-tab-pane label="邮件" name="email"></el-tab-pane>
          </el-tabs>
          <div
            v-infinite-scroll="load"
            style="height: 294px; overflow-y: auto"
            :infinite-scroll-disabled="state.disabled"
            v-if="state.activeName == 'notification'"
          >
            <el-link
              href="https://element-plus.org"
              target="_blank"
              v-for="item in state.count"
              :key="item"
              style="line-height: 28px"
              >{{ item }}</el-link
            >
            <el-empty description="暂无通知" v-if="state.count.length == 0" />
          </div>
          <div v-if="state.activeName == 'email'">
            <el-empty description="暂无邮件" />
          </div>
          <el-divider />
          <div class="clearInfo" pointer>
            <el-button type="primary" link @click="clearNotific"
              ><el-icon><CircleClose /></el-icon>清空消息</el-button
            >
          </div>
        </el-popover>
    </li>
  </el-tooltip>
  </ul>
  <!-- 主题设置 -->
  <Theme_Drawer v-model:visible="state.visible" />
  <!-- 搜索 -->
  <el-dialog
    draggable
    :show-close="false"
    :model-value="state.dialogVisible"
    :before-close="handleClose"
    width="50%"
  >
    <el-form :model="state.form" @submit.native.prevent>
      <el-form-item label=" ">
        <el-input
          v-model="state.form.name"
          style="height: 60px"
          @keydown.enter="handleDown"
        >
          <template #prefix>
            <el-icon class="el-input__icon" style="font-size: 20px"
              ><search
            /></el-icon>
          </template>
        </el-input>
      </el-form-item>
    </el-form>
  </el-dialog>
  <!-- 锁屏 -->
  <teleport to="body">
    <transition name="move">
      <Lockoverlay v-if="UserStore.LockComputed"></Lockoverlay>
    </transition>
  </teleport>
</template>
<script setup>
// 头部工具栏
import {
  reactive,
  toRefs,
  onBeforeMount,
  onMounted,
  onBeforeUnmount,
} from "vue";
import { useRouter } from "vue-router";
import {
  TagviewsStore,
  i18nStore,
  themeStore,
  UserInfoStore,
} from "@/stores/index.js";
import { SetStorage, GetStorage } from "@/utils/auth.js";
import Theme_Drawer from "./components/Theme_Drawer.vue";
import Mitt from "@/utils/mitt.js";
import { watch } from "vue";
const useViewStore = TagviewsStore();
const useI18nStore = i18nStore();
const theme_store = themeStore();
const UserStore = UserInfoStore();
defineOptions({
  name: "Tool",
});
const router = useRouter();
const state = reactive({
  isFull: true,
  visible: false,
  isShow: false,
  dialogVisible: false,
  isPopover: false,
  activeName: "notification",
  form: {
    name: "",
  },
  count: ["响应式布局适配兼容电脑、平板"],
  disabled: false,
});

const handleClose = () => {
  state.dialogVisible = false;
  state.form.name = "";
};
const handleDown = (e) => {
  window.open("https://www.baidu.com/baidu?wd=" + state.form.name);
};
// 锁屏
const handleLock = () => {
  UserStore.$patch({
    Lockvisible: true,
  });
  SetStorage("Lockvisible", true);
};
// 搜索
const handleSearch = () => {
  state.dialogVisible = true;
};

// 通知
const handleNotice = () => {};
const clearNotific = () => {
  state.count = [];
};
const load = () => {
  if (state.count.length == 2) {
    state.disabled = true;
    return;
  } else {
    state.count = [...state.count, "vite搭建,开发速度快,友好的交互体验"];
  }
};
// 全屏
const handleFullScreen = () => {
  let main = document.body;
  if (state.isFull) {
    if (main.requestFullscreen) {
      main.requestFullscreen();
    } else if (main.mozRequestFullScreen) {
      main.mozRequestFullScreen();
    } else if (main.webkitRequestFullScreen) {
      main.webkitRequestFullScreen();
    } else if (main.msRequestFullscreen) {
      main.msRequestFullscreen();
    }
  } else {
    if (document.exitFullscreen) {
      document.exitFullscreen();
    } else if (document.mozCancelFullScreen) {
      document.mozCancelFullScreen();
    } else if (document.webkitCancelFullScreen) {
      document.webkitCancelFullScreen();
    } else if (document.msExitFullscreen) {
      document.msExitFullscreen();
    }
  }
};
// 国际化
const handleCommand = (v) => {
  useI18nStore.EditLocale(v);
};
// 主题
const handleTheme = () => {
  state.visible = true;
};
// 刷新
const handleReFresh = () => {
  useViewStore.refreshCurrentView();
};
onMounted(() => {
  // 注册全屏事件
  document.addEventListener("fullscreenchange", (v) => {
    state.isFull = !state.isFull;
  });
  document.addEventListener("mozfullscreenchange", (v) => {
    state.isFull = !state.isFull;
  });
  document.addEventListener("webkitfullscreenchange", (v) => {
    state.isFull = !state.isFull;
  });
  document.addEventListener("msfullscreenchange", (v) => {
    state.isFull = !state.isFull;
  });
  // window+j 快速锁屏
  document.addEventListener("keyup", (e) => {
    if (e.keyCode == 74 && e.metaKey) {
      UserStore.$patch({
        Lockvisible: true,
      });
    }
  });
  Mitt.on("editVisible", (val) => {
    state.visible = val;
  });
});
onBeforeUnmount(() => {
  Mitt.off("editVisible");
});
</script>
<style lang="scss" scoped>
.list {
  margin: 0 0 0 auto;
  display: flex;
  align-items: center;
  li {
    margin: 0 0 0 20px;
    display: flex;
    align-items: center;
    cursor: pointer;
  }
}
.clearInfo {
  text-align: center;
}

</style>

