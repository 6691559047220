import { defineStore } from "pinia"
import { computed, reactive, toRefs } from "vue"
import { GetStorage,SetStorage } from "@/utils/auth.js"
import config from "@/config/index.js"
const { theme_config } = config
export const themeStore = defineStore('theme', () => {
	const state = reactive({
        theme: JSON.parse(GetStorage('theme')) ||{...theme_config},
		isFlag:false, //修改主题的标识
	})
	const setTheme = () => {
        return new Promise( async(resolve,reject)=>{
			let variables=null
			let themeStyle=null
			switch(state.theme.themeName){
				case 'blue-black':
				themeStyle=await import('/src/styles/variables/blue-black-variables.module.scss')
				break;
				case 'blue-white':
				themeStyle=await import('/src/styles/variables/blue-white-variables.module.scss')
				break;
				case 'green-black':
				themeStyle=await import('/src/styles/variables/green-black-variables.module.scss')
				break;
				case 'green-white':
				themeStyle=await import('/src/styles/variables/green-white-variables.module.scss')
				break;
				case 'purple-black':
				themeStyle=await import('/src/styles/variables/purple-black-variables.module.scss')
				break;
				case 'purple-white':
				themeStyle=await import('/src/styles/variables/purple-white-variables.module.scss')
				break;
				case 'red-black':
				themeStyle=await import('/src/styles/variables/red-black-variables.module.scss')
				break;
				case 'red-white':
				themeStyle=await import('/src/styles/variables/red-white-variables.module.scss')
				break;
			}
			variables=themeStyle.default
                const style = `
					--el-color-grey: ${variables['el-color-grey']};
					--el-color-black: ${variables['el-color-black']};
					--el-color-primary: ${variables['el-color-primary']};
					--el-color-primary-light-1: ${variables['el-color-primary-light-1']};
					--el-color-primary-light-2: ${variables['el-color-primary-light-2']};
					--el-color-primary-light-3: ${variables['el-color-primary-light-3']};
					--el-color-primary-light-4: ${variables['el-color-primary-light-4']};
					--el-color-primary-light-5: ${variables['el-color-primary-light-5']};
					--el-color-primary-light-6: ${variables['el-color-primary-light-6']};
					--el-color-primary-light-7: ${variables['el-color-primary-light-7']};
					--el-color-primary-light-8: ${variables['el-color-primary-light-8']};
					--el-color-primary-light-9: ${variables['el-color-primary-light-9']};
					--el-color-success: ${variables['el-color-success']};
					--el-color-success-light: ${variables['el-color-success-light']};
					--el-color-success-lighter: ${variables['el-color-success-lighter']};
					--el-color-warning: ${variables['el-color-warning']};
					--el-color-warning-light: ${variables['el-color-warning-light']};
					--el-color-warning-lighter: ${variables['el-color-warning-lighter']};
					--el-color-danger: ${variables['el-color-danger']};
					--el-color-danger-light: ${variables['el-color-danger-light']};
					--el-color-danger-lighter: ${variables['el-color-danger-lighter']};
					--el-color-error: ${variables['el-color-error']};
					--el-color-error-light: ${variables['el-color-error-light']};
					--el-color-error-lighter: ${variables['el-color-error-lighter']};
					--el-color-info: ${variables['el-color-info']};
					--el-color-info-light: ${variables['el-color-info-light']};
					--el-color-info-lighter: ${variables['el-color-info-lighter']};
					--el-border-radius-base: ${variables['el-border-radius-base']};
					--el-color-transition: ${variables['el-color-transition']};
					--el-menu-bg-color: ${variables['el-menu-bg-color']};
					--el-menu-text-color: ${variables['el-menu-text-color']};
					--el-menu-hover-text-color: ${variables['el-menu-hover-text-color']};
					--el-menu-hover-bg-color: ${variables['el-menu-hover-bg-color']};
					--el-menu-active-color: ${variables['el-menu-active-color']};
					--el-color-primary-dark-2: ${variables['el-color-primary-dark-2']};
					--el-color-success-dark-2: ${variables['el-color-success-dark-2']};
					--el-color-warning-dark-2: ${variables['el-color-warning-dark-2']};
					--el-color-danger-dark-2: ${variables['el-color-danger-dark-2']};
					--el-color-error-dark-2: ${variables['el-color-error-dark-2']};
					--el-color-info-dark-2: ${variables['el-color-info-dark-2']};
					--el-menu-width: ${state.theme.menuWidth};
					--el-menu-minWidth: ${state.theme.menuMinWidth};
				`
                document.getElementsByTagName('body')[0].setAttribute('style', style)
				state.isFlag=true
                resolve()
        })
	}
    const saveTheme= () => {
		SetStorage('theme',JSON.stringify(state.theme))
	}
	const themeData = computed(() => {
		return state.theme
	})
	return {
		themeData,
        setTheme,
        saveTheme,
		...toRefs(state),
	}
})