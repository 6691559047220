<template>
  <el-main class="_main" :style="theme_store.themeData.isDark?'background:#141414':'background:#fff'">
    <slot></slot>
  </el-main>
</template>
<script setup>
import { UserInfoStore, TagviewsStore, themeStore } from "@/stores/index.js";
const theme_store = themeStore();
defineOptions({
  name: "Main",
});
</script>
<style lang="scss" scoped>
._main{
  // background-color: #fff;
  overflow: visible !important;
  min-height: calc(100% - 50px);
  box-sizing: border-box;
}
</style>
