<template>
  <Drawer
    title="主题"
    v-model:visible="props.visible"
    v-model:size="state.size"
    @handleClose="handleClose"
  >
    <el-form
      :model="theme_store.theme"
      label-width="130px"
      label-position="left"
    >
      <el-form-item label="主题">
        <el-select v-model="theme_store.theme.themeName" @change="themeChange">
          <el-option
            v-for="item in state.options"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          />
        </el-select>
      </el-form-item>
      <el-form-item label="暗黑模式">
        <el-switch
          :modelValue="theme_store.theme.isDark"
          @change="handleDark"
          active-action-icon="Moon"
          inactive-action-icon="Sunny"
        />
      </el-form-item>
      <el-form-item label="菜单宽度">
        <!-- <el-input v-model="theme_store.theme.menuWidth" clearable @blur="themeChange"> </el-input> -->
        <el-select v-model="theme_store.theme.menuWidth" @change="themeChange">
          <el-option
            v-for="item in state.WidthList"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          />
        </el-select>
      </el-form-item>
      <el-form-item label="头部固定">
        <el-switch v-model="theme_store.theme.fixedHeader" />
      </el-form-item>
      <el-form-item label="进度条">
        <el-switch v-model="theme_store.theme.showProgressBar" />
      </el-form-item>
      <el-form-item label="标签">
        <el-switch v-model="theme_store.theme.showTabs" />
      </el-form-item>
      <el-form-item>
        <template #label>
          <div style="display: flex; align-items: center">
            <span style="margin: 0 4px 0 0">标签风格</span>
            <el-tooltip effect="dark" content="标签开启时生效" placement="top">
              <el-icon pointer><QuestionFilled /></el-icon>
            </el-tooltip>
          </div>
        </template>
        <el-select
          v-model="theme_store.theme.tabsBarStyle"
          :disabled="!theme_store.theme.showTabs"
        >
          <el-option
            v-for="item in state.tabsBarList"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          />
        </el-select>
      </el-form-item>
      <el-form-item>
        <template #label>
          <div style="display: flex; align-items: center">
            <span style="margin: 0 4px 0 0">标签图标</span>
            <el-tooltip effect="dark" content="标签开启时生效" placement="top">
              <el-icon pointer><QuestionFilled /></el-icon>
            </el-tooltip>
          </div>
        </template>
        <el-switch
          v-model="theme_store.theme.showTabsIcon"
          :disabled="!theme_store.theme.showTabs"
        />
      </el-form-item>
      <el-form-item label="国际化">
        <el-switch v-model="theme_store.theme.showLanguage" />
      </el-form-item>
      <el-form-item label="刷新">
        <el-switch v-model="theme_store.theme.showRefresh" />
      </el-form-item>
      <el-form-item label="搜索">
        <el-switch v-model="theme_store.theme.showSearch" />
      </el-form-item>
      <el-form-item label="通知">
        <el-switch v-model="theme_store.theme.showNotice" />
      </el-form-item>
      <el-form-item label="全屏">
        <el-switch v-model="theme_store.theme.showFullScreen" />
      </el-form-item>
      <el-form-item label="锁屏">
        <el-switch v-model="theme_store.theme.showLock" />
      </el-form-item>
    </el-form>
    <template #footer>
      <div style="flex: auto">
        <el-button @click="resetDefault">恢复默认</el-button>
        <el-button type="primary" @click="confirmClick">保存</el-button>
      </div>
    </template>
  </Drawer>
</template>

<script setup>
import { reactive, toRefs, onBeforeMount, onMounted, watch } from "vue";
defineExpose({
  name: "Theme_Drawer",
});
import { themeStore } from "@/stores/index.js";
import config from "@/config/index.js";
import { removeStorage } from "@/utils/auth.js";
const { theme_config } = config;
import { useDark, useToggle } from "@vueuse/core";
import { SetStorage, GetStorage } from "@/utils/auth.js";
const theme_store = themeStore();
const isDark = useDark(); //返回系统暗/亮boolean
console.log(isDark.value);
const toggleDark = useToggle(isDark); //初始化设置 当前系统暗黑模式开启 true false关闭
const props = defineProps({
  visible: {
    type: Boolean,
  },
});
const emit = defineEmits(["update:visible"]);
const state = reactive({
  options: [
    {
      value: "blue-black",
      label: "蓝黑",
    },
    {
      value: "blue-white",
      label: "蓝白",
    },
    {
      value: "green-black",
      label: "绿黑",
    },
    {
      value: "green-white",
      label: "绿白",
    },
    {
      value: "purple-black",
      label: "紫黑",
    },
    {
      value: "purple-white",
      label: "紫白",
    },
    {
      value: "red-black",
      label: "红黑",
    },
    {
      value: "red-white",
      label: "红白",
    },
  ],
  tabsBarList: [
    {
      value: "CardTags",
      label: "常规",
    },
    {
      value: "Card",
      label: "卡片",
    },
    {
      value: "MoveTags",
      label: "滑动",
    },
  ],
  WidthList: [
    {
      value: "244px",
      label: "244px",
    },
    {
      value: "255px",
      label: "255px",
    },
    {
      value: "266px",
      label: "266px",
    },
    {
      value: "277px",
      label: "277px",
    },
    {
      value: "288px",
      label: "288px",
    },
  ],
  size: 300,
});
function updateView(event) {}
// 同步暗黑模式状态
watch(
  () => isDark.value,
  () => {
    console.log(isDark.value);
    theme_store.theme.isDark = isDark.value;
    const data = JSON.parse(GetStorage("theme"));
    if (data) {
      theme_store.saveTheme();
    }
  }
);
const handleDark = v => {
  //在不支持的浏览器里不做动画
  if (!document.startViewTransition) {
    return;
  }
  // 开始一次视图过渡截图
  const transition = document.startViewTransition(() => {
      // 执行暗黑模式切换
      toggleDark();
  });
  transition.ready.then(() => {
    const x = window.innerWidth;
    const y = 0;
    //计算按钮到最远点的距离用作裁剪圆形的半径
    const endRadius = Math.hypot(
      Math.max(x, innerWidth - x),
      Math.max(y, innerHeight - y)
    );
    const clipPath = [
      `circle(0px at ${x}px ${y}px)`,
      `circle(${endRadius}px at ${x}px ${y}px)`,
    ];
    //开始动画
    document.documentElement.animate(
      {
        clipPath: isDark.value ? clipPath.reverse() : clipPath,
      },
      {
        duration: 500,
        easing: "ease-in",
        pseudoElement: isDark.value ? "::view-transition-old(root)" : "::view-transition-new(root)",
      }
    );
  });
  console.log(theme_store.theme)
};
const resetDefault = () => {
  console.log(isDark.value);
  theme_store.$patch(state => {
    state.theme = { ...theme_config, isDark: isDark.value };
  });
  theme_store.setTheme();
  removeStorage("theme");
  handleClose(false);
};
const confirmClick = () => {
  theme_store.saveTheme();
  handleClose(false);
};
const handleClose = v => {
  emit("update:visible", v);
};
const themeChange = () => {
  theme_store.isFlag = false;
  theme_store.setTheme();
};
onBeforeMount(() => {});
onMounted(() => {});
</script>
<style lang="scss"></style>
<style>
::view-transition-new(root),
::view-transition-old(root) {
  /* 关闭默认动画，否则影响自定义动画的执行 */
  animation: none;
}
.dark::view-transition-old(root) {
  z-index: 100;
}
</style>
