import { useDark, useToggle } from '@vueuse/core'
const isDark = useDark() //返回系统暗/亮boolean值 
console.log(isDark.value)
// 主题配置
const config={
    theme_config:{
        //主题名称 blue-black、blue-white、green-black、green-white、purple-black、purple-white、red-black、red-white
        themeName:'blue-black', 
        // 菜单背景 none、sny-background  -未完善暂时无法使用
        background: 'none',
        // 菜单宽度
        menuWidth: '266px',
        // 菜单折叠宽度 不支持修改
        menuMinWidth: '64px',
        // 是否固定头部固定   
        fixedHeader: true,  
        // 是否开启顶部进度条
        showProgressBar: true,
        // 是否开启标签页
        showTabs: true,
        // 显示标签页时标签页样式：常规CardTags、卡片风格Card、滑动MoveTags 
        tabsBarStyle: 'CardTags',
        // 是否显示标签页图标
        showTabsIcon: true,
        // 是否开启语言选择组件
        showLanguage: true,
        // 是否开启刷新组件
        showRefresh: true,
        // 是否开启搜索组件
        showSearch: true,
        // 是否开启主题组件
        showTheme: true,
        // 是否开启通知组件
        showNotice: true,
        // 是否开启全屏组件
        showFullScreen: true,
        // 是否开启锁屏
        showLock: true,
        // 是否开启暗黑模式 默认高亮模式 false 亮 true 暗
        isDark:isDark.value,  //初始化获取系统色值 只有ui页面色值与系统色值一致时 切换系统色值才有效,ui本地存储状态为auto
    }
}
export default config